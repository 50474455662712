import React from 'react';

// eslint-disable-next-line
const Footer = ({ }) => {
  return (
    <div
      className="
        py-4 md:py-0 md:h-12 w-full flex flex-col-reverse md:flex-row
        items-center justify-between px-12
        bg-slate-900 text-slate-50 text-sm lg:text-base
      "
      id="footer"
    >
      <div className="mb-1 md:mb-0">
        <span>&copy; 2024 All rights reserved.</span>
      </div>
      <div className="mb-1 md:mb-0">
        <button className="opacity-75 hover:opacity-100 mx-1">
          <i className="fa-brands fa-linkedin"></i>
        </button>
        <button className="opacity-75 hover:opacity-100 mx-1">
          <i className="fa-brands fa-square-facebook"></i>
        </button>
        <button className="opacity-75 hover:opacity-100 mx-1">
          <i className="fa-brands fa-instagram"></i>
        </button>
        <span className="mx-1">@innovufilm</span>
      </div>
      <div className="mb-1 md:mb-0">
        <a
          href="tel:+14168009613"
          className="opacity-75 hover:opacity-100"
        >
          <i className="fa-solid fa-phone mx-2"></i>
          +1 (416) 800 9613
        </a>
      </div>
      <div className="mb-1 md:mb-0">
        <a
          href="mailto:sales@luvinova.com"
          className="opacity-75 hover:opacity-100"
        >
          <i className="fa-solid fa-envelope mx-2"></i>
          sales@luvinova.com
        </a>
      </div>
    </div>
  );
};

export default Footer;