import React, { useEffect } from 'react';
import Landing from "../sections/Landing";
import Tech from "../sections/Tech";
import Applications from "../sections/Applications";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";
import About from "../sections/About";
import Estimate from "../sections/Estimate";

// eslint-disable-next-line
const Home = ({ }) => {
  useEffect(() => {
    console.log("hello")
    const onScroll = (event) => {
      console.log(event);
      console.log(window.scrollY);
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="">
      <Landing />
      <About />
      <Tech />
      <Applications />
      <Estimate />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;