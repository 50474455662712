import React from 'react';
import data from "../data/data.json"

// eslint-disable-next-line
const About = ({ }) => {
  return (
    <div className="w-screen mt-12 mb-16 xl:my-8 flex justify-center">
      <div className="w-4/5 flex flex-col">
        <span className="text-xl font-semibold text-slate-500 mb-4">About Us</span>
        <span className="text-lg lg:text-md">
          {data?.about?.en}
        </span>
      </div>
    </div>
  );
};

export default About;