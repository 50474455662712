import React, { useRef, useState } from 'react';
import contact from "../images/contact.jpg";
import anime from "animejs";
import data from "../data/data.json";

const INITAL_FORM_DATA = {
  name: "",
  email: "",
  phoneNumber: "",
  subject: "",
  message: ""
};

// eslint-disable-next-line
const Contact = ({ }) => {
  const [formData, setFormData] = useState(INITAL_FORM_DATA);
  const submitDiv = useRef();

  const setFormField = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const submitForm = () => {
    // Add validation
    console.log(formData);

    setFormData(INITAL_FORM_DATA);
    animateSuccess();
  };

  const animateSuccess = () => {
    submitDiv.current.style.display = "flex";
    anime({
      targets: "#submitDiv",
      opacity: [
        { value: 1, duration: 750, easing: 'easeInOutSine' },
        { value: 0, duration: 750, delay: 500, easing: 'easeInOutSine' }
      ],
      complete: () => {
        setTimeout(() => submitDiv.current.style.display = "none", 250);
      }
    });
  };

  return (
    <div
      className="my-8 md:my-14 lg:my-8 lg:h-screen w-screen flex flex-col md:flex-row items-center justify-center"
      id="contact"
    >
      <div
        className="h-[75%] w-2/5 xl:w-1/2 bg-cover bg-right rounded-e-full hidden lg:flex"
        style={{ backgroundImage: `url(${contact})` }}
      ></div>
      <div className="h-full w-full lg:w-3/5 xl:w-1/2 flex flex-col items-center justify-center">
        <div className="w-5/6 flex flex-col mb-8">
          <div
            className="
            h-24 md:h-48 w-full rounded-s-full rounded-e-full mb-12
            bg-center bg-cover flex lg:hidden
          "
            style={{
              backgroundImage: `url(${contact})`
            }}
          ></div>
          <span className="text-xl font-semibold text-slate-500 mb-4">
            Questions For Us?
          </span>
          <span className="text-lg lg:text-md">
            {data?.questions?.en}
          </span>
        </div>
        <div className="w-5/6 bg-slate-300 bg-opacity-45 flex flex-col p-10 lg:py-5 relative">
          <div
            className="
              absolute top-0 left-0 h-full w-full bg-green-100
              flex-col items-center justify-center hidden
            "
            id="submitDiv"
            ref={submitDiv}
          >
            <i
              className="fa-solid fa-circle-check text-green-900 text-8xl"
              id="submitIcon"
            ></i>
            <span
              className="text-green-950 mt-2"
              id="submitSpan"
            >
              Message succesfully sent
            </span>
          </div>
          <div className="w-full flex flex-col md:flex-row mb-4">
            <input
              className="w-full mb-4 md:mb-0 md:w-1/2 md:mx-2 px-5 h-[40px] caret-slate-900"
              type="text"
              placeholder="Name"
              value={formData?.name}
              onChange={(event) => setFormField("name", event?.target?.value)}
            />
            <input
              className="w-full md:w-1/2 md:mx-2 px-5 h-[40px] caret-slate-900"
              type="text"
              placeholder="Email"
              value={formData?.email}
              onChange={(event) => setFormField("email", event?.target?.value)}
            />
          </div>
          <div className="w-full flex flex-col md:flex-row md:mb-4">
            <input
              className="w-full md:w-1/2 md:mx-2 px-5 h-[40px] caret-slate-900"
              type="text"
              placeholder="Phone Number"
              value={formData?.phoneNumber}
              onChange={(event) => setFormField("phoneNumber", event?.target?.value)}
            />
            <div className="w-1/2 mx-2 px-5 h-[40px]"></div>
          </div>
          <div className="w-full flex mb-4">
            <input
              className="w-full md:mx-2 px-5 h-[40px] caret-slate-900"
              type="text"
              placeholder="Subject"
              value={formData?.subject}
              onChange={(event) => setFormField("subject", event?.target?.value)}
            />
          </div>
          <div className="w-full flex mb-4">
            <textarea
              className="w-full md:mx-2 px-5 py-3 resize-none caret-slate-900"
              name=""
              id=""
              rows="6"
              placeholder="Message"
              value={formData?.message}
              onChange={(event) => setFormField("message", event?.target?.value)}
            ></textarea>
          </div>
          <button
            className="
              h-[40px] md:ms-2 mdw-[97.5%]
              transition duration-300
              bg-slate-700 hover:bg-slate-900 text-slate-50 font-semibold
            "
            onClick={() => submitForm()}
          >Send</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;