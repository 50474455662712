import React, { useState } from 'react';
import landingClear from "../images/landing-clear.jpg";
import landingActive from "../images/landing-active.jpg";
import logo from "../images/logo.png";
import data from "../data/data.json";
import anime from "animejs";

// eslint-disable-next-line
const Landing = ({ }) => {
  const [active, setActive] = useState(true);

  const activateGlass = () => {
    anime({
      targets: ".mask",
      opacity: (active) ? 0 : 1,
      easing: "easeInOutQuad",
      duration: 500
    })
    setActive(!active);
  };

  return (
    <div
      className="
        h-screen w-screen relative
        bg-cover bg-center 
      "
      id="landing"
      style={{
        backgroundImage: `url(${landingClear})`
      }}
    >
      <div
        className="
          absolute top-0 left-0 w-full h-full
          bg-cover bg-center
          mask
        "
        style={{
          backgroundImage: `url(${landingActive})`
        }}
      ></div>
      <div
        className={`
          
          absolute top-0 left-0 w-full h-full
          ${active ? "bg-opacity-100" : "bg-0opacity-0"}
        `}
      ></div>
      <div
        className="
          h-full w-full
          
          flex flex-col items-center justify-center
        "
      >
        <img src={logo} className="w-72 md:w-96 z-20" alt="InnovuFilm Logo" />
        <span className="font-semibold md:text-2xl z-10">
          {data?.tagline?.en}
        </span>
        <div className="absolute bottom-8 md:bottom-8 lg:bottom-24 w-full flex justify-center">
          <div
            className={`
              px-3 py-2 rounded-full tooltip
              hover:opacity-100 hover:animate-none border-4
              cursor-pointer transition duration-300 ${active ?
                "animate-pulse bg-slate-50 text-slate-900 opacity-100" :
                "bg-slate-900 text-slate-50 opacity-60"
              }
              z-20
            `}
            onClick={() => activateGlass()}
          >
            {active ?
              <i className="fa-solid fa-bolt text-2xl"></i> :
              <i className="fa-solid fa-power-off text-2xl"></i>
            }
            {active ?
              <span className="tooltip-text">Power Off</span> :
              <span className="tooltip-text">Power On</span>
            }
          </div>
        </div>
      </div>
      <div className="
        h-2/3 w-full absolute bottom-0
        bg-gradient-to-b from-transparent to-slate-50
      "></div>
    </div>
  );
};

export default Landing;