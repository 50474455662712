import React from 'react';
import tech from "../images/tech.jpg";
import data from "../data/data.json";

// eslint-disable-next-line
const Tech = ({ }) => {
  return (
    <div className="my-14 lg:my-40 lg:h-screen w-screen flex items-center" id="tech">
      <div
        className="
          h-full w-full lg:w-1/2 xl:w-2/5 flex flex-col lg:items-center lg:justify-center
          px-10 md:px-20 lg:px-0
        "
      >
        <div
          className="
            h-24 md:h-48 w-full rounded-s-full rounded-e-full mb-12
            bg-center bg-cover flex lg:hidden
          "
          style={{
            backgroundImage: `url(${tech})`
          }}
        ></div>
        <div className="lg:h-1/2 lg:w-3/5 xl:w-1/2 flex flex-col justify-center">
          <span className="text-xl font-semibold text-slate-500 mb-4">
            Our Technology
          </span>
          <span className="text-lg lg:text-md">
            {data?.tech?.en}
          </span>
        </div>
      </div>
      <div
        className="
          h-[90%] lg:w-1/2 xl:w-3/5 bg-cover bg-center
          rounded-s-full hidden lg:flex
        "
        style={{
          backgroundImage: `url(${tech})`
        }}
      ></div>
    </div>
  );
};

export default Tech;