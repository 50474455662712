import React, { useState } from 'react';

const ESTIMATE_TYPE_MULTIPLIER = {
  Plain: 35,
  Simple: 40,
  Dynamic: 45
};

// eslint-disable-next-line
const Estimate = ({ }) => {
  const [estimateData, setEstimateData] = useState({
    type: "Plain",
    height: null,
    width: null,
    estimate: 0
  });

  const updateEstimate = (key, value) => {
    setEstimateData({ ...estimateData, [key]: value });
    const data = { ...estimateData, [key]: value };

    const estimate =
      ((data?.height * data?.width) * ESTIMATE_TYPE_MULTIPLIER[data?.type]);

    setEstimateData({ ...data, estimate: estimate });

    console.log(data);
    console.log(estimate);
  };

  return (
    <div className="w-screen my-8 md:mt-24 md:mb-8 flex justify-center">
      <div className="w-4/5 flex flex-col">
        <span className="text-xl font-semibold text-slate-500 mb-4">
          Estimate
        </span>
        <div
          className="
            w-full flex flex-col md:flex-row md:relative
            lg:border lg:border-slate-900 lg:p-4
          "
        >
          <select
            className="px-5 h-[40px] md:w-[120px] mb-4 md:mb-0"
            value={estimateData?.type}
            onChange={(event) => updateEstimate(
              "type", event?.target?.value
            )}
            name=""
            id=""
          >
            <option value="Plain">Plain</option>
            <option value="Simple">Simple</option>
            <option value="Dynamic">Dynamic</option>
          </select>
          <input
            className="
              w-full md:w-[120px] md:mx-2 px-5 
              h-[40px] mb-4 md:mb-0 caret-slate-900
            "
            type="number"
            placeholder="Height (ft)"
            value={estimateData?.height}
            onChange={(event) => updateEstimate(
              "height", parseInt(event?.target?.value)
            )}
          />
          <input
            className="
              w-full md:w-[120px] md:mx-2 px-5 
              h-[40px] mb-4 md:mb-0 caret-slate-900
            "
            type="number"
            placeholder="Width (ft)"
            value={estimateData?.width}
            onChange={(event) => updateEstimate(
              "width", parseInt(event?.target?.value)
            )}
          />
          <div
            className="
              w-full md:w-[240px] px-5 md:px-0 h-[40px] mb-4 md:mb-0
              flex md:justify-start items-center
            "
          >
            Estimate: {estimateData?.estimate}
            <div className="flex flex-col items-center ms-2 w-[36px]">
              <small>$</small>
              <hr className="w-full" />
              <small>sq ft</small>
            </div>
          </div>
          <button
            className="
              h-[40px] ms-2 w-[97.5%]
              transition duration-300
              md:w-[80px] md:absolute md:right-0 lg:right-4
              bg-slate-700 hover:bg-slate-900 text-slate-50 font-semibold
            "
          >Ask Us</button>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default Estimate;