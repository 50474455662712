import React from 'react';
import logo from "../images/logo.png";

// eslint-disable-next-line
const Logo = ({ }) => {
  return (
    <div className="fixed top-0 left-5">
      <img src={logo} className="w-36" alt="InnovuFilm Logo" />
    </div>
  );
};

export default Logo;