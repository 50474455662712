import './App.css';
import Logo from "./components/Logo";
import Home from "./routes/Home";

const App = () => {
  return (
    <div className="App h-screen w-screen bg-slate-50 overflow-y-scroll overflow-x-hidden">
      <Logo />
      <Home />
    </div>
  );
}

export default App;
