import React, { useState } from 'react';
import data from "../data/data.json";
import hospitalClearImage from "../images/app-hospital-clear.jpg";
import hospitalActiveImage from "../images/app-hospital-active.jpg";
import meetingClearImage from "../images/app-meeting-clear.jpg";
import meetingActiveImage from "../images/app-meeting-active.jpg";
import windowClearImage from "../images/app-window-clear.jpg";
import windowActiveImage from "../images/app-window-active.jpg";

// eslint-disable-next-line
const Applications = ({ }) => {
  const [active, setActive] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const images = [
    windowClearImage,
    meetingClearImage,
    hospitalClearImage,
  ];
  const activeImages = [
    windowActiveImage,
    meetingActiveImage,
    hospitalActiveImage,
  ];
  const MAX_IMAGE_INDEX = 2;

  const toggleGlass = () => {
    setActive(!active);
  };

  return (
    <div
      className="
        w-screen flex flex-col
        items-center justify-center
      "
      id="applications"
    >
      <div className="w-4/5 flex justify-start">
        <span className="text-xl font-semibold text-slate-500 mb-4">
          Applications of our Product
        </span>
      </div>
      <div className="w-4/5 h-[80vh] bg-slate-400 rounded-xl flex relative">
        {
          (imageIndex > 0) &&
          <div
            className={`
            px-3 py-2 rounded-full tooltip
            absolute top-[50%] left-5
            hover:opacity-100 hover:animate-none border-4
            cursor-pointer transition duration-300
            bg-slate-900 text-slate-50 opacity-60
            hover:bg-slate-50 hover:text-slate-900
          `}
            onClick={() => setImageIndex(imageIndex - 1)}
          >
            <i className="fa-solid fa-angles-left text-xl"></i>
            <span className="tooltip-text">Previous</span>
          </div>
        }
        {
          (imageIndex < MAX_IMAGE_INDEX) &&
          <div
            className={`
            px-3 py-2 rounded-full tooltip
            absolute top-[50%] right-5
            hover:opacity-100 hover:animate-none border-4
            cursor-pointer transition duration-300
            bg-slate-900 text-slate-50 opacity-60
            hover:bg-slate-50 hover:text-slate-900
          `}
            onClick={() => setImageIndex(imageIndex + 1)}
          >
            <i className="fa-solid fa-angles-right text-xl"></i>
            <span className="tooltip-text">Next</span>
          </div>
        }
        <div
          className={`
            px-3 py-2 rounded-full tooltip
            absolute bottom-5 left-[50%]
            hover:opacity-100 hover:animate-none border-4
            cursor-pointer transition duration-300 ${active ?
              "animate-pulse bg-slate-50 text-slate-900 opacity-100" :
              "bg-slate-900 text-slate-50 opacity-60"
            }
          `}
          onClick={() => toggleGlass()}
        >
          {active ?
            <i className="fa-solid fa-bolt text-2xl"></i> :
            <i className="fa-solid fa-power-off text-2xl"></i>
          }
          {active ?
            <span className="tooltip-text">Power Off</span> :
            <span className="tooltip-text">Power On</span>
          }
        </div>
        <div className="h-full w-full rounded-xl app-img">
          <img
            src={(active) ? activeImages?.at(imageIndex) : images?.at(imageIndex)}
            alt=""
            className="h-full w-full rounded-xl"
          />
        </div>
      </div>
      <span
        className="w-4/5 mt-4 md:mt-8 text-lg lg:text-md"
      >
        {data?.apps?.en}
      </span>
    </div>
  );
};

export default Applications;